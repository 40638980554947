enum NotificationMessage {
  CONNECTION_SAVED = "Connection saved",
  FAILED_TO_DELETE_MAPPING = "Failed to delete mapping",
  FAILED_TO_GET_AUTOTASK_CFG = "Failed to get Autotask configuration",
  FAILED_TO_GET_CONNECTWISE_CFG = "Failed to get ConnectWise configuration",
  FAILED_TO_GET_SYNCRO_CFG = "Failed to get Syncro configuration",
  FAILED_TO_QUEUE_SYNC_REQUEST = "Failed to queue sync request",
  FAILED_TO_RESET_SYNC_STATUS = "Failed to reset sync status",
  FAILED_TO_SAVE_CONNECTION = "Failed to save connection",
  FAILED_TO_SAVE_MAPPING = "Failed to save mapping",
  FAILED_TO_SAVE_PRICE_RULE = "Failed to save price rule",
  FAILED_TO_SAVE_PROGRAM_CONFIG = "Failed to save program configuration",
  FAILED_TO_TEST_PRICE_RULE = "Failed to test price rule",
  FAILED_TO_UPDATE_LAST_INV_DATE = "Failed to update last processed invoice date",
  FAILED_TO_UPDATE_PRICE_RULE = "Failed to update price rule",
  FAILED_TO_UPDATE_PRODUCT_MAPPING = "Failed to update product mapping",
  LAST_INV_DATE_UPDATED = "Last processed invoice date updated",
  MAPPING_DELETED = "Mapping deleted",
  MAPPING_SAVED = "Mapping saved",
  PRICE_RULE_SAVED = "Price rule saved",
  PRICE_RULE_UPDATED = "Price rule updated",
  PRODUCT_MAPPING_UPDATED = "Product mapping updated",
  PROGRAM_CONFIG_SAVED = "Program configuration saved",
  SYNC_STATUS_WAS_RESET = "Sync status was reset",
  SYNCING_BILLING_QUEUED = "Syncing billing queued",
  SYNCING_PRODUCTS_QUEUED = "Syncing products queued",
  UNKNOWN_ERROR = "Something went wrong. Try again later or contact support",
}

export default NotificationMessage;
